
	frappe.templates['print_layout'] = `<!-- <div class="form-print-wrapper frappe-card">
	<div class="print-toolbar row">
		<div class="col-xs-3">
			<div class="flex">
				<select class="print-preview-select input-sm form-control"></select>
				<button class="btn btn-default btn-sm border print-preview-refresh" type="button">{%= __("Refresh") %}</button>
			</div>
		</div>
		<div class="col-xs-2">
			<select class="languages input-sm form-control"
				placeholder="{{ __("Language") }}"></select></div>
		<div class="col-xs-2">
			<div class="checkbox small" style="margin-top: 7px; margin-bottom: 0px;">
				<label>
					<input type="checkbox" class="print-letterhead text-muted"/>
					{%= __("Letter Head") %}</label>
			</div>
		</div>
		<div class="col-xs-5 text-right">
			<a class="close btn-print-close" style="margin-top: 2px; margin-left: 10px;">&times;</a>
			<div class="btn-group">
				<a class="btn btn-default btn-sm"
					style="border-top-right-radius:0px; border-bottom-right-radius: 0px;"
					data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
					<span>{%= __("Settings") %}</span>
					<span>
						<svg class="icon icon-xs">
							<use href="#icon-select"></use>
						</svg>
					</span>
				</a>
				<ul class="dropdown-menu print-format-dropdown" style="max-height: 300px;
					overflow-y: auto; left: auto;">
					<li><a class="dropdown-item" href="/app/Form/Print Settings">
						{%= __("Print Settings") %}</a></li>
					<li><a class="btn-printer-setting dropdown-item" style="display: none;">
						{%= __("Raw Printing Settings") %}</a></li>
					<li><a class="btn-print-edit dropdown-item">
						{%= __("Customize") %}</a></li>
				</ul>
				<a class="btn-print-preview btn-sm btn btn-default">
					{%= __("Full Page") %}</a>
				<a class="btn-download-pdf btn-sm btn btn-default">
					{%= __("PDF") %}</a>
				<a class="btn-print-print btn-sm btn btn-default">
					<strong>{%= __("Print") %}</strong></a>
			</div>
		</div>
	</div>
	<div class="print-preview-wrapper">
		<div class="print-preview">
			<div class="print-format"></div>
		</div>
		<div class="page-break-message text-muted text-center text-medium margin-top"></div>
	</div>
</div> -->
`;
