
	frappe.templates['form_footer'] = `<div class="form-footer">
	<div class="after-save">
		<div class="comment-box"></div>
		<div class="timeline"></div>
	</div>
	<button class="scroll-to-top btn btn-default icon-btn" onclick="frappe.utils.scroll_to(0)">
		<svg class="icon icon-xs"><use href="#icon-up-line"></use></svg>
	</button>
</div>
`;
