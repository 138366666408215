
	frappe.templates['report_links'] = `<div class="form-documents">
	{% for (var i=0; i < reports.length; i++) { %}
		{% if (i % 3 === 0) { %}
		<div class="row">
		{% } %}
			<div class="col-md-4">
				<div class="form-link-title">
					<span>{{ __(reports[i].label) }}</span>
				</div>
				{% for (let j=0; j < reports[i].items.length; j++) { %}
					{% let report = reports[i].items[j]; %}
					<div class="document-link" data-report="{{ report }}">
						<div class="report-link-badge" data-report="{{ report }}">
							<a class="report-link">{{ __(report) }}</a>
						</div>
					</div>
				{% } %}
			</div>
		{% if (i % 3 === 2 || i === (reports.length - 1)) { %}
		</div>
		{% } %}
	{% } %}
</div>
`;
